.vertical-menu {
  width: 290px;
  overflow: hidden;
}

.sub-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.mm-show {
  max-height: 500px; /* Adjust according to content */
  transition: max-height 0.3s ease-in;
}

.mm-collapse {
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.mm-active > a {
  background-color: #f8f9fa;
}

.mm-active > a .bx {
  color: #343a40;
}
