.loggedOutContainer {
    position: relative;
    height: 100vh;
    /* background: url('/path/to/background.jpg') no-repeat center center fixed; Replace with your background image */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Optional: darken the background image */
}

.content {
    position: relative;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    z-index: 2;
    text-align: center;
}

.logo img {
    max-width: 150px;
    margin-bottom: 20px;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.subtitle {
    font-size: 16px;
    margin-bottom: 30px;
    color: #666;
}

.footerText {
    margin-top: 20px;
    font-size: 14px;
    color: #888;
}

.footerText a {
    color: #007bff;
    text-decoration: none;
}

.footerText a:hover {
    text-decoration: underline;
}
